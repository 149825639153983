import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import {
    MainContainer,
    SmallDataContainer,
    SmallDataItem,
    SmallDataTitle,
    Header
} from '../../components/StyledBlocks'
import { privatePaymentList } from '../../firebase/firestore/privatePaymentList'
import privatePaymentStats from '../../firebase/firestore/privatePaymentStats'
import formatNumber from '../../utils/formatNumber'
import { UserContext } from '../../firebase/UserContext'
import getUserSign from '../../utils/getUserSign'
import Loading from '../../components/Loading'
import PrivateHistoryItem from '../../components/PrivateHistoryItem'
import PrivateFilterForm, { rangeTypes } from '../../components/PrivateFilterForm'

const Container = styled.div`
  margin: 0 10px;
`

// const CategoryContainer = styled(SmallDataContainer)`
// display: flex;
// flex-wrap: wrap;
// `
//
// const CategoryItem = styled(SmallDataItem)`
// flex: 1 0 26%;
// `

const PrivateHistoryPage = () => {
    const userAuth = useContext(UserContext)
    const userSign = getUserSign(userAuth)
    const [filters, setFilters] = useState(null)
    const [generalStats, setGeneralStats] = useState(null)
    const [categoryStats, setCategoryStats] = useState(null)
    const [deletedID, setDeletedID] = useState(null)

    useEffect(() => {
        if(filters) {
            let m, y = null
            if(filters.range === rangeTypes.current) {
                m = new Date().getMonth() + 1
                y = new Date().getFullYear()
            }
            if(filters.range === rangeTypes.custom) {
                m = filters.month
                y = filters.year
            }
            privatePaymentStats(userSign, parseInt(m), parseInt(y), filters.type).then(data => setGeneralStats(data))
            privatePaymentList(userSign, parseInt(m), parseInt(y), filters.type).then(data => setCategoryStats(data))
        }else{
            privatePaymentStats(userSign, null, null).then(data => setGeneralStats(data))
            privatePaymentList(userSign, null, null).then(data => setCategoryStats(data))
        }
    }, [filters, userSign, deletedID])

    if(!categoryStats || !generalStats) {
        return (<Loading/>)
    }else{
        return (
            <MainContainer>
                <Container>
                    <Header>Filtry</Header>
                    <PrivateFilterForm filterChanged={filters => setFilters(filters)}/>
                    <Header>Podsumowanie</Header>
                    <SmallDataContainer>
                        <SmallDataItem><SmallDataTitle>Własne</SmallDataTitle>{formatNumber(generalStats.total.own)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Wspólne</SmallDataTitle>{formatNumber(generalStats.total.common)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Specjalne</SmallDataTitle>{formatNumber(generalStats.total.special)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Wszystkie</SmallDataTitle>{formatNumber(generalStats.total.all)}</SmallDataItem>
                    </SmallDataContainer>
                    {/*<Header>Podsumowanie wg kategorii</Header>*/}
                    {/*<CategoryContainer>*/}
                    {/*    {*/}
                    {/*        categoryStats.totalCategories.map((item, idx) => (*/}
                    {/*            <CategoryItem key={idx}>*/}
                    {/*                <SmallDataTitle>{item.category}</SmallDataTitle>*/}
                    {/*                {formatNumber(item.total)}*/}
                    {/*            </CategoryItem>*/}
                    {/*        ))*/}
                    {/*    }*/}
                    {/*</CategoryContainer>*/}
                    <Header>Historia płatności</Header>
                    {
                        categoryStats.items.map((item, idx) => (
                            <PrivateHistoryItem key={idx} item={item} onDelete={id => setDeletedID(id)} deleteButton />
                        ))
                    }
                </Container>
            </MainContainer>
        )
    }
}

export default PrivateHistoryPage
