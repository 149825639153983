import firebase from '../firebase'

const paymentStats = (month, year) => {
    return firebase.firestore().collection('payments').get()
        .then(collection => {
            let count = 0
            const total = { ad: 0, am: 0, dm: 0, da: 0 }
            const fullRange = !Number.isInteger(month) || !Number.isInteger(year)
            collection.docs.forEach(payment => {
                const d = payment.data()
                if(fullRange || (d.month === month && d.year === year)) {
                    count++
                    total.ad += d.ad
                    total.am += d.am
                    total.dm += d.dm
                    total.da += d.da
                }
            })
            const forA = Math.round((total.ad + (total.am / 2)) * 100) / 100
            const forD = Math.round((total.da + (total.dm / 2)) * 100) / 100
            const advantage = forA === forD ? '=' : forA > forD ? 'A' : 'D'
            const diff = Math.max(forA, forD) - Math.min(forA, forD)
            return { total, forA, forD, advantage, diff, count }
        })
        .catch(err => alert(`ERROR ${err.code}`))
}

export default paymentStats
