import React from 'react'
import styled from 'styled-components'
import NavMenu from './NavMenu'

const Container = styled.div`
background-color: #cfcfcf;
width: 100%;
height: 100vh;
`

const Content = styled.div`
background-color: #cfcfcf;
min-height: 100%;
padding: 5px 5px 90px 5px;
box-shadow: 0 0 30px 6px rgba(0,0,0,0.75);
`

const Layout = ({children}) => (
    <Container>
        <Content>{children}</Content>
        <NavMenu/>
    </Container>
)

export default Layout
