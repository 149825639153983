import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import {Button} from 'react-bootstrap'
import { useHistory } from 'react-router'

const Title = styled.div`
position: fixed;
width: 100%;
max-width: 800px;
text-align: center;
font-size: 24px;
margin-top: 30px;
`

const ButtonContainer = styled.div`
  position: absolute;
  width: calc(100% - 45px);
  max-width: 755px;
  margin: 0 25px 0 20px;
  bottom: 90px;
  display: flex;
`

const StyledBtn = styled(Button)`
    margin: 0 10px;
    padding: 30px 20px;
    width: 100%;
    flex: 1;
        
    .fa-icon {
      display: block;
      margin: 0 auto 10px auto;
      font-size: 52px;
    }
`

const Btn = ({icon, text, path}) => {
    const history = useHistory()
    return (
        <StyledBtn variant={'secondary'} onClick={() => history.push(path)}>
            <FontAwesomeIcon icon={icon} className={'fa-icon'}/>
            {text}
        </StyledBtn>
    )
}

const SectionButton = ({title, rootPath}) => (
    <>
        <Title>{title}</Title>
        <ButtonContainer>
            <Btn text={'Płatności prywatne'} icon={faEyeSlash} path={`${rootPath}/private`}/>
            <Btn text={'Płatności publiczne'} icon={faEye} path={`${rootPath}/public`}/>
        </ButtonContainer>
    </>
)

export default SectionButton
