import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import {
    MainContainer,
    SmallDataContainer,
    SmallDataItem,
    SmallDataTitle,
    Header
} from '../../components/StyledBlocks'
import { paymentList } from '../../firebase/firestore/paymentList'
import formatNumber from '../../utils/formatNumber'
import {UserContext} from '../../firebase/UserContext'
import getUserSign from '../../utils/getUserSign'
import Loading from '../../components/Loading'
import HistoryItem from '../../components/HistoryItem'
import FilterForm, { rangeTypes } from '../../components/FilterForm'

const Container = styled.div`
  margin: 0 10px;
`

const PublicHistoryPage = () => {
    const userAuth = useContext(UserContext)
    const userSign = getUserSign(userAuth)
    const [filters, setFilters] = useState(null)
    const [stats, setStats] = useState(null)
    const [deletedID, setDeletedID] = useState(null)

    useEffect(() => {
        if(filters) {
            const sign = filters.sign ? userSign : null
            let m, y = null
            if(filters.range === rangeTypes.current) {
                m = new Date().getMonth() + 1
                y = new Date().getFullYear()
            }
            if(filters.range === rangeTypes.custom) {
                m = filters.month
                y = filters.year
            }
            paymentList(sign, parseInt(m), parseInt(y)).then(data => setStats(data))
        }else{
            paymentList(null, null, null).then(data => setStats(data))
        }
    }, [filters, userSign, deletedID])

    if(!stats) {
        return (<Loading/>)
    }else{
        return (
            <MainContainer>
                <Container>
                    <Header>Filtry</Header>
                    <FilterForm filterChanged={filters => setFilters(filters)}/>
                    <Header>Podsumowanie</Header>
                    <SmallDataContainer>
                        <SmallDataItem><SmallDataTitle>Ania (D)</SmallDataTitle>{formatNumber(stats.total.ad)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Ania (M)</SmallDataTitle>{formatNumber(stats.total.am)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Daniel (M)</SmallDataTitle>{formatNumber(stats.total.dm)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Daniel (A)</SmallDataTitle>{formatNumber(stats.total.da)}</SmallDataItem>
                    </SmallDataContainer>
                    <Header>Historia płatności</Header>
                    {
                        stats.items.map((item, idx) => (
                            <HistoryItem key={idx} item={item} onDelete={id => setDeletedID(id)} deleteButton />
                        ))
                    }
                </Container>
            </MainContainer>
        )
    }
}

export default PublicHistoryPage
