import firebase from '../firebase'
import dateSortFunction from '../../utils/dateSortFunction'
import { paymentTypes } from '../../components/PrivateFilterForm'

export const privatePaymentList = (sign, month, year, typeFilter = paymentTypes.all) => {
    return firebase.firestore().collection('privatePayments').get()
        .then(collection => {
            const totalCategories = []
            const items = []
            const fullRange = !Number.isInteger(month) || !Number.isInteger(year)
            collection.docs.forEach(payment => {
                const d = payment.data()
                if(d.sign === sign) {
                    if(fullRange || (d.month === month && d.year === year)) {
                        if(typeFilter === paymentTypes.all || typeFilter === d.paymentType) {
                            const categoryObj = totalCategories.find(i => i.category === d.desc)
                            if(categoryObj) {
                                categoryObj.total += d.amount
                            }else{
                                totalCategories.push({
                                    category: d.desc,
                                    total: d.amount
                                })
                            }
                            items.push({
                                ...d,
                                id: payment.id
                            })
                        }
                    }
                }
            })
            items.sort(dateSortFunction)
            return { totalCategories, items }
        })
        .catch(err => alert(`ERROR ${err.code}`))
}

export const deletePrivatePayment = id => {
    return firebase.firestore().collection('privatePayments').doc(id).delete()
        .catch(err => alert(`ERROR ${err.code}`))
}

export const addPrivatePayment = (sign, date, paymentType, amount, description) => {
    const obj = {
        sign: sign,
        amount: amount,
        paymentType: paymentType,
        day: date.day,
        month: date.month,
        year: date.year,
        desc: description
    }
    return firebase.firestore().collection('privatePayments').add(obj)
        .then(ref => ({ id: ref.id, payment: obj }))
        .catch(err => alert(`ERROR ${err.code}`))
}
