import React, { useState, useEffect, useContext } from 'react'
import {
    MainContainer,
    SmallDataContainer,
    SmallDataItem,
    SmallDataTitle,
    BigDataContainer,
    Header
} from '../components/StyledBlocks'
import paymentStats from '../firebase/firestore/paymentStats'
import privatePaymentStats from '../firebase/firestore/privatePaymentStats'
import formatNumber from '../utils/formatNumber'
import {UserContext} from '../firebase/UserContext'
import getUserSign from '../utils/getUserSign'
import Loading from '../components/Loading'
import OptionSwitch from '../components/OptionSwitch'

const advantageSign = (sign, advantage) => {
    if(advantage === '=') {
        return '+'
    }else{
        return advantage === sign ? '+' : '-'
    }
}

const cardSwitch = [
    { name: 'Publiczny', value: true },
    { name: 'Prywatny', value: false }
]

const HomePage = () => {
    const userSign = getUserSign(useContext(UserContext))
    const [publicCard, setPublicCard] = useState(true)
    const [generalStats, setGeneralStats] = useState(null)
    const [monthlyStats, setMonthlyStats] = useState(null)

    useEffect(() => {
        const month = new Date().getMonth() + 1
        const year = new Date().getFullYear()
        if(publicCard) {
            paymentStats(null, null).then(data => setGeneralStats(data))
            paymentStats(month, year).then(data => setMonthlyStats(data))
        }else{
            privatePaymentStats(userSign, null, null).then(data => setGeneralStats(data))
            privatePaymentStats(userSign, month, year).then(data => setMonthlyStats(data))
        }
    }, [publicCard])

    const onCardChange = value => {
        setGeneralStats(null)
        setMonthlyStats(null)
        setPublicCard(value)
    }

    if(!generalStats || !monthlyStats) {
        return (<><OptionSwitch items={cardSwitch} value={publicCard} onChange={onCardChange} /><Loading /></>)
    }else{
        if(publicCard) {
            return (
                <MainContainer>
                    <OptionSwitch items={cardSwitch} value={publicCard} onChange={onCardChange} />
                    <Header>Bilans miesięczny</Header>
                    <SmallDataContainer>
                        <SmallDataItem><SmallDataTitle>Ania (D)</SmallDataTitle>{formatNumber(monthlyStats.total.ad)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Ania (M)</SmallDataTitle>{formatNumber(monthlyStats.total.am)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Daniel (M)</SmallDataTitle>{formatNumber(monthlyStats.total.dm)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Daniel (A)</SmallDataTitle>{formatNumber(monthlyStats.total.da)}</SmallDataItem>
                    </SmallDataContainer>
                    <Header>Bilans ogólny</Header>
                    <SmallDataContainer>
                        <SmallDataItem><SmallDataTitle>Ania (D)</SmallDataTitle>{formatNumber(generalStats.total.ad)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Ania (M)</SmallDataTitle>{formatNumber(generalStats.total.am)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Daniel (M)</SmallDataTitle>{formatNumber(generalStats.total.dm)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Daniel (A)</SmallDataTitle>{formatNumber(generalStats.total.da)}</SmallDataItem>
                    </SmallDataContainer>
                    <Header>Równowaga wydatków</Header>
                    <BigDataContainer>{advantageSign(userSign, generalStats.advantage)}{formatNumber(generalStats.diff)}</BigDataContainer>
                    <Header>Statystyki</Header>
                    <SmallDataContainer>
                        <SmallDataItem><SmallDataTitle>W tym miesiącu</SmallDataTitle>{monthlyStats.count}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Ogółem w systemie</SmallDataTitle>{generalStats.count}</SmallDataItem>
                    </SmallDataContainer>
                </MainContainer>
            )
        }else{
            return (
                <MainContainer>
                    <OptionSwitch items={cardSwitch} value={publicCard} onChange={onCardChange} />
                    <Header>Bilans miesięczny</Header>
                    <SmallDataContainer>
                        <SmallDataItem><SmallDataTitle>Własne</SmallDataTitle>{formatNumber(monthlyStats.total.own)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Wspólne</SmallDataTitle>{formatNumber(monthlyStats.total.common)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Specjalne</SmallDataTitle>{formatNumber(monthlyStats.total.special)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Wszystkie</SmallDataTitle>{formatNumber(monthlyStats.total.all)}</SmallDataItem>
                    </SmallDataContainer>
                    <Header>Bilans ogólny</Header>
                    <SmallDataContainer>
                        <SmallDataItem><SmallDataTitle>Własne</SmallDataTitle>{formatNumber(generalStats.total.own)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Wspólne</SmallDataTitle>{formatNumber(generalStats.total.common)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Specjalne</SmallDataTitle>{formatNumber(generalStats.total.special)}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Wszystkie</SmallDataTitle>{formatNumber(generalStats.total.all)}</SmallDataItem>
                    </SmallDataContainer>
                    <Header>Statystyki</Header>
                    <SmallDataContainer>
                        <SmallDataItem><SmallDataTitle>W tym miesiącu</SmallDataTitle>{monthlyStats.count}</SmallDataItem>
                        <SmallDataItem><SmallDataTitle>Ogółem w systemie</SmallDataTitle>{generalStats.count}</SmallDataItem>
                    </SmallDataContainer>
                </MainContainer>
            )
        }
    }
}

export default HomePage
