export default month => {
    switch (month) {
        case 1: return 'sty'
        case 2: return 'lut'
        case 3: return 'mar'
        case 4: return 'kwi'
        case 5: return 'maj'
        case 6: return 'cze'
        case 7: return 'lip'
        case 8: return 'sie'
        case 9: return 'wrz'
        case 10: return 'paź'
        case 11: return 'lis'
        case 12: return 'gru'
        default: return null
    }
}
