import firebase from '../firebase'
import { paymentTypes } from '../../components/PrivateFilterForm'

const paymentStats = (sign, month, year, targetType = paymentTypes.all) => {
    return firebase.firestore().collection('privatePayments').get()
        .then(collection => {
            let count = 0
            const total = { own: 0, common: 0, special: 0, all: 0 }
            const fullRange = !Number.isInteger(month) || !Number.isInteger(year)
            collection.docs.forEach(payment => {
                const d = payment.data()
                if(sign === d.sign) {
                    if(fullRange || (d.month === month && d.year === year)) {
                        switch (d.paymentType) {
                            case paymentTypes.own:
                                if(targetType === paymentTypes.own || targetType === paymentTypes.all) {
                                    count++
                                    total.own += d.amount
                                    total.all += d.amount
                                }
                                break
                            case paymentTypes.common:
                                if(targetType === paymentTypes.common || targetType === paymentTypes.all) {
                                    count++
                                    total.common += d.amount
                                    total.all += d.amount
                                }
                                break
                            case paymentTypes.special:
                                if(targetType === paymentTypes.special || targetType === paymentTypes.all) {
                                    count++
                                    total.special += d.amount
                                    total.all += d.amount
                                }
                                break
                            default:
                                count++
                                total.all += d.amount
                        }
                    }
                }
            })
            return { total, count }
        })
        .catch(err => alert(`ERROR ${err.code}`))
}

export default paymentStats
