import HomePage from './views/HomePage'

import HistoryPage from './views/HistoryPage'
import PrivateHistoryPage from './views/privatePayments/PrivateHistoryPage'
import PublicHistoryPage from './views/publicPayments/PublicHistoryPage'

import NewPaymentPage from './views/NewPaymentPage'
import NewPrivatePaymentPage from './views/privatePayments/NewPrivatePaymentPage'
import NewPublicPaymentPage from './views/publicPayments/NewPublicPaymentPage'


import DictionaryPage from './views/DictionaryPage'
import ProfilePage from './views/ProfilePage'

export default [
    { path: '/panel', exact: true, name: 'Start', component: HomePage },
    { path: '/panel/history', exact: true, name: 'Historia', component: HistoryPage },
    { path: '/panel/history/private', exact: true, name: 'Prywatna historia', component: PrivateHistoryPage },
    { path: '/panel/history/public', exact: true, name: 'Publiczna historia', component: PublicHistoryPage },
    { path: '/panel/new', exact: true, name: 'Dodaj nowy', component: NewPaymentPage },
    { path: '/panel/new/private', exact: true, name: 'Dodaj nowy prywatny', component: NewPrivatePaymentPage },
    { path: '/panel/new/public', exact: true, name: 'Dodaj nowy publiczny', component: NewPublicPaymentPage },
    { path: '/panel/dictionary', exact: true, name: 'Dictionary', component: DictionaryPage },
    { path: '/panel/profile', exact: true, name: 'Profile', component: ProfilePage },
]
