import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faHistory, faPlusSquare, faBook, faUser } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const NavContainer = styled.div`
  width: 100%;
  max-width: 800px;
  position: fixed;
  bottom: 0;
  background-color: #a0a0a0;
  height: 70px;
  display: flex;
  border-top: 1px #888888 solid;
  z-index: 9999;
`

const NavItem = styled(Link)`
flex: 1;
color: #fff;
padding: 14px 2px 5px 2px;
text-decoration: none;
text-align: center;
line-height: 20px;
font-size: 12px;

&.active {
background-color: #0c0c0c;
}

&:hover {
background-color: #0c0c0c;
text-decoration: none;
color: #fff;
}

.menu-icon {
font-size: 22px;
display: block;
margin: 0 auto 4px auto;
}
`

const items = [
    { icon: faHome, name: 'Start', path: '/panel' },
    { icon: faHistory, name: 'Historia', path: '/panel/history', alternativePaths: ['/panel/history/private', '/panel/history/public'] },
    { icon: faPlusSquare, name: 'Dodaj', path: '/panel/new', alternativePaths: ['/panel/new/private', '/panel/new/public'] },
    { icon: faBook, name: 'Słownik', path: '/panel/dictionary' },
    { icon: faUser, name: 'Profil', path: '/panel/profile' }
]

const NavMenu = () => {
    const location = useLocation()
    return (
        <NavContainer>
            {
                items.map(item => (
                    <NavItem key={item.name}
                             to={item.path}
                             className={(location.pathname === item.path || (item.alternativePaths && item.alternativePaths.includes(location.pathname))) && 'active'}>
                        <FontAwesomeIcon className={'menu-icon'} icon={item.icon}/>
                        {item.name}
                    </NavItem>
                ))
            }
        </NavContainer>
    )
}

export default NavMenu
