import firebase from '../firebase'

export const exportPublicPayments = () => {
    return firebase.firestore().collection('payments').get()
        .then(collection => {
            const obj = {}
            collection.docs.forEach(payment => obj[payment.id] = payment.data())
            return JSON.stringify(obj)
        })
        .catch(err => alert(`ERROR ${err.code}`))
}

export const exportPrivatePayments = sign => {
    return firebase.firestore().collection('privatePayments').get()
        .then(collection => {
            const obj = {}
            collection.docs.forEach(payment => {
                const d = payment.data()
                if(d.sign === sign) obj[payment.id] = d
            })
            return JSON.stringify(obj)
        })
        .catch(err => alert(`ERROR ${err.code}`))
}

export const exportPublicDictionary = () => {
    return firebase.firestore().collection('dictionary').get()
        .then(collection => {
            const obj = {}
            collection.docs.forEach(item => {
                const d = item.data()
                if(!d.hasOwnProperty('sign')) obj[item.id] = d
            })
            return JSON.stringify(obj)
        })
        .catch(err => alert(`ERROR ${err.code}`))
}

export const exportPrivateDictionary = sign => {
    return firebase.firestore().collection('dictionary').get()
        .then(collection => {
            const obj = {}
            collection.docs.forEach(item => {
                const d = item.data()
                if(d.hasOwnProperty('sign') && d.sign === sign) obj[item.id] = d
            })
            return JSON.stringify(obj)
        })
        .catch(err => alert(`ERROR ${err.code}`))
}
