export default userAuth => {
    if(!userAuth) return null
    switch (userAuth.email) {
        case 'danielalberski@gmail.com': return 'D'
        case 'anna.morfoza@gmail.com': return 'A'
        case 'redarkpl@gmail.com': return 'A'
        default:
            alert('USER SIGN ERROR!')
            return null
    }
}
