import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyCu-8kbk5IQQuus8GdCNCjAmv4t_j3MUWg",
    authDomain: "lub-pay.firebaseapp.com",
    projectId: "lub-pay",
    storageBucket: "lub-pay.appspot.com",
    messagingSenderId: "808955700106",
    appId: "1:808955700106:web:955fd608a952fa33a0de73"
}
firebase.initializeApp(firebaseConfig)

export default firebase
