import React, { useEffect, useState } from 'react'
import {Button, Modal} from 'react-bootstrap'
import styled from 'styled-components'
import Loading from './Loading'
import { dictionaryList } from '../firebase/firestore/dictionaryList'

const ListContainer = styled.div`
  border: 1px #c4c4c4 solid;
  border-radius: 4px;
  padding: 4px;
  max-height: 50vh;
  overflow-y: scroll;
`

const ListItem = styled(Button)`
  text-align: left;
`

const DictionaryPicker = ({privateSign, show, onClose, onChange}) => {
    const [list, setList] = useState(null)

    useEffect(() => {
        dictionaryList(privateSign).then(data => setList(data))
    }, [])

    const click = name => {
        if(onChange) onChange(name)
        closeMe()
    }

    const closeMe = () => onClose && onClose()

    return(
        <Modal show={show} onHide={closeMe} size={'lg'} centered>
            <Modal.Header closeButton>
                <Modal.Title>Pobierz opis ze słownika</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    list
                    ?
                        <ListContainer>
                            {
                                list.map(item => (
                                    <ListItem key={item.id} variant={'light'} onClick={() => click(item.name)} block>
                                        {item.name}
                                        {item.sign && <i> (Prywatny)</i>}
                                    </ListItem>
                                ))
                            }
                        </ListContainer>
                        :
                        <Loading/>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeMe}>Zamknij</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DictionaryPicker
