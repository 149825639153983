import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Form, Row, Col } from 'react-bootstrap'
import OptionSwitch from './OptionSwitch'

export const rangeTypes = {
    full: 'FULL',
    current: 'CURRENT',
    custom: 'CUSTOM'
}

const Container = styled(Form)`
margin: 5px 3px 0 3px;
`

const StyledRow = styled(Row)`
margin-top: 5px;
`

const MonthSelect = ({value, onChange}) => {
    return (
        <Form.Control as="select" value={value} onChange={onChange}>
            {
                [...Array(12).keys()].map(i => (
                    <option key={i} value={i+1}>{i+1}</option>
                ))
            }
        </Form.Control>
    )
}

const YearSelect = ({value, onChange}) => {
    const sy = new Date().getFullYear() - 2
    return (
        <Form.Control as="select" value={value} onChange={onChange}>
            {
                [...Array(5).keys()].map(i => (
                    <option key={i} value={sy+i}>{sy+i}</option>
                ))
            }
        </Form.Control>
    )
}

const signSwitch = [
    { name: 'Wszystkie', value: false },
    { name: 'Tylko moje', value: true }
]

const rangeSwitch = [
    { name: 'Wszystkie', value: rangeTypes.full },
    { name: 'Ten miesiąc', value: rangeTypes.current },
    { name: 'Określony', value: rangeTypes.custom }
]

const FilterForm = ({filterChanged}) => {
    const [sign, setSign] = useState(false)
    const [range, setRange] = useState(rangeTypes.current)
    const [month, setMonth] = useState(new Date().getMonth() + 1)
    const [year, setYear] = useState(new Date().getFullYear())

    useEffect(() => {
        if(filterChanged) filterChanged({ sign, range, month, year })
    }, [sign, range, month, year])

    return (
        <Container>
            <StyledRow>
                <Col><OptionSwitch items={signSwitch} value={sign} onChange={e => setSign(e)}/></Col>
            </StyledRow>
            <StyledRow>
                <Col><OptionSwitch items={rangeSwitch} value={range} onChange={e => setRange(e)}/></Col>
            </StyledRow>
            {
                range === rangeTypes.custom
                &&
                <StyledRow>
                    <Col><MonthSelect value={month} onChange={e => setMonth(e.target.value)}/></Col>
                    <Col><YearSelect value={year} onChange={e => setYear(e.target.value)}/></Col>
                </StyledRow>
            }
        </Container>
    )
}

export default FilterForm
