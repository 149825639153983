import React, {useState, useEffect, useContext} from 'react'
import styled from 'styled-components'
import { Form, InputGroup } from 'react-bootstrap'
import getUserSign from '../../utils/getUserSign'
import { UserContext } from '../../firebase/UserContext'
import { Button } from 'react-bootstrap'
import {
    MainContainer,
    Header
} from '../../components/StyledBlocks'
import { privatePaymentList, addPrivatePayment } from '../../firebase/firestore/privatePaymentList'
import PrivateHistoryItem from '../../components/PrivateHistoryItem'
import DatePicker from '../../components/DatePicker'
import OptionSwitch from '../../components/OptionSwitch'
import DictionaryPicker from '../../components/DictionaryPicker'
import { paymentTypes as paymentTypeValues } from '../../components/PrivateFilterForm'

const Container = styled.div`
  margin: 6px 3px;
`

const NewPrivatePaymentPage = () => {
    const userSign = getUserSign(useContext(UserContext))
    const [lastPayment, setLastPayment] = useState(null)
    const [date, setDate] = useState(null)
    const [paymentType, setPaymentType] = useState(paymentTypeValues.own)
    const [amount, setAmount] = useState('')
    const [parsedAmount, setParsedAmount] = useState(0)
    const [desc, setDesc] = useState('')
    const [openPicker, setOpenPicker] = useState(false)
    const [verified, setVerified] = useState(false)
    const [sending, setSending] = useState(false)

    useEffect(() => {
        privatePaymentList(userSign, null, null).then(data => {
            if(data.items.length > 0) setLastPayment(data.items[0])
        })
    }, [])

    useEffect(() => {
        const s = amount.toString().replace(',', '.')
        const f = parseFloat(s)
        setParsedAmount(isNaN(f) || f < 0 ? 0 : f)
    }, [amount])

    useEffect(() => {
        setVerified(date !== null && parsedAmount > 0 && desc.length > 2 && desc.length < 25)
    }, [date, parsedAmount, desc])

    const submitData = () => {
        setSending(true)
        addPrivatePayment(userSign, date, paymentType, parsedAmount, desc)
            .then(newPayment => {
                setLastPayment(newPayment.payment)
                setAmount('0.00')
                setDesc('')
                setSending(false)
            })
            .catch(() => setSending(false))
    }

    return (
        <MainContainer>
            <Header>Ostatnio dodana pozycja</Header>
            {lastPayment ? <PrivateHistoryItem item={lastPayment}/> : <span>Nie znaleziono</span>}
            <Header>Data płatności</Header>
            <DatePicker onChange={e => setDate(e)}/>
            <Header>Typ oraz wartość</Header>
            <Container>
                <OptionSwitch value={paymentType} onChange={setPaymentType} items={[
                    { name: 'Własna', value: paymentTypeValues.own },
                    { name: 'Wspólna', value: paymentTypeValues.common },
                    { name: 'Specjalna', value: paymentTypeValues.special },
                ]}/>
            </Container>
            <Container>
                <Form.Control type={'number'}
                              value={amount}
                              placeholder={'0.00'}
                              onChange={e => setAmount(e.target.value)} />
            </Container>
            <Header>Opis płatności</Header>
            <Container>
                <InputGroup>
                    <Form.Control type={'text'}
                                  value={desc}
                                  onChange={e => setDesc(e.target.value)}
                                  maxLength={24} />
                    <InputGroup.Append>
                        <Button variant={'secondary'} onClick={() => setOpenPicker(true)}>Słownik</Button>
                    </InputGroup.Append>
                </InputGroup>
            </Container>
            <DictionaryPicker privateSign={userSign} show={openPicker} onClose={() => setOpenPicker(false)} onChange={e => setDesc(e)}/>
            <Button variant={verified ? 'success' : 'light'} disabled={!verified || sending} onClick={submitData} block>
                Dodaj prywatną płatność
            </Button>
        </MainContainer>
    )
}

export default NewPrivatePaymentPage
