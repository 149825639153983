import React, {useState, useEffect, useContext} from 'react'
import styled from 'styled-components'
import {Button, Form, Modal} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import {
    MainContainer,
    Header
} from '../components/StyledBlocks'
import { dictionaryList, deleteFromDictionary } from '../firebase/firestore/dictionaryList'
import DictionaryNewItemForm from '../components/DictionaryNewItemForm'
import Loading from '../components/Loading'
import getUserSign from '../utils/getUserSign'
import {UserContext} from '../firebase/UserContext'
import OptionSwitch from '../components/OptionSwitch'

const SwitchContainer = styled(Form)`
  margin: 5px 3px 0 3px;
`

const DictionaryItem = styled.div`
  background-color: #b1b1b1;
  margin: 8px 6px 0 6px;
  padding: 8px;
  text-align: left;
`

const DeleteButton = styled(Button)`
  width: 50px;
  margin-right: 10px;
`

const filterTypes = {
    all: 'ALL',
    onlyPublic: 'PUBLIC',
    onlyPrivate: 'PRIVATE'
}

const filterSwitch = [
    { name: 'Wszystkie', value: filterTypes.all },
    { name: 'Publiczne', value: filterTypes.onlyPublic },
    { name: 'Prywatne', value: filterTypes.onlyPrivate }
]

const DictionaryPage = () => {
    const userSign = getUserSign(useContext(UserContext))
    const [filter, setFilter] = useState(filterTypes.all)
    const [list, setList] = useState(null)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        dictionaryList(
            filter !== filterTypes.onlyPublic ? userSign : null,
            filter === filterTypes.onlyPrivate,
        ).then(data => setList(data))
    }, [filter])

    const updateList = newItem => {
        const newList = [...list, newItem]
        newList.sort((a, b) => {
            if(a.name < b.name) return -1
            if(a.name > b.name) return 1
            return 0
        })
        setList(newList)
    }

    const deleteItem = () => {
        if(selected) {
            deleteFromDictionary(selected).then(() => setList(list.filter(item => item.id !== selected)))
            setSelected(null)
        }
    }

    if(!list) {
        return (<Loading/>)
    }else{
        return (
            <MainContainer>
                <Header>Nowa pozycja</Header>
                <DictionaryNewItemForm itemAdded={updateList}/>
                <Header>Lista słownika</Header>
                <SwitchContainer>
                    <OptionSwitch items={filterSwitch} value={filter} onChange={setFilter} />
                </SwitchContainer>
                {
                    list.map(item => (
                        <DictionaryItem key={item.id}>
                            <DeleteButton variant={'danger'} size={'sm'} onClick={() => setSelected(item.id)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </DeleteButton>
                            {item.name}
                            {item.sign && <i> (Prywatny)</i>}
                        </DictionaryItem>
                    ))
                }
                <Modal show={selected !== null} onHide={() => setSelected(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Potwierdź operację</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Czy na pewno chcesz usunąć pozycję "{selected && list.find(i => i.id === selected).name}"?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={deleteItem}>Usuń</Button>
                        <Button variant="secondary" onClick={() => setSelected(null)}>Anuluj</Button>
                    </Modal.Footer>
                </Modal>
            </MainContainer>
        )
    }
}

export default DictionaryPage
