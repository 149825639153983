import React, {useContext, useState} from 'react'
import styled from 'styled-components'
import {Button} from 'react-bootstrap'
import firebase from 'firebase'
import {UserContext} from '../firebase/UserContext'
import {
    exportPublicPayments,
    exportPrivatePayments,
    exportPublicDictionary,
    exportPrivateDictionary
} from '../firebase/firestore/databaseExports'
import getUserSign from '../utils/getUserSign'

const Panel = styled.div`
text-align: center;
margin: 50px 20px 0 20px;
`

const Btn = styled(Button)`
margin-top: 25px;
`

const logout = () => {
    firebase.auth().signOut().catch(err => alert(`AUTH ERROR: ${err}`))
}

const ProfilePage = () => {
    const user = useContext(UserContext)
    const userSign = getUserSign(user)
    const [exportData, setExportData] = useState(null)
    const [fetchingInProgress, setFetchingInProgress] = useState(false)
    const [fileName, setFileName] = useState('exportdata')

    const fetchData = typeID => {
        setExportData(null)
        setFetchingInProgress(true)
        switch (typeID) {
            case 'PUB-PAYMENT':
                setFileName('PublicPaymentDataExport')
                exportPublicPayments().then(data => setExportData(data))
                break
            case 'PRIV-PAYMENT':
                setFileName('PrivatePaymentDataExport')
                exportPrivatePayments(userSign).then(data => setExportData(data))
                break
            case 'PUB-DICTIONARY':
                setFileName('PublicDictionaryDataExport')
                exportPublicDictionary().then(data => setExportData(data))
                break
            case 'PRIV-DICTIONARY':
                setFileName('PrivateDictionaryDataExport')
                exportPrivateDictionary(userSign).then(data => setExportData(data))
                break
            default:
                setFileName('exportdata')
                setExportData(null)
        }
    }

    const clickExportPublicPayments = () => fetchData('PUB-PAYMENT')
    const clickExportPrivatePayments = () => fetchData('PRIV-PAYMENT')
    const clickExportPublicDictionary = () => fetchData('PUB-DICTIONARY')
    const clickExportPrivateDictionary = () => fetchData('PRIV-DICTIONARY')

    const downloadExportData = () => {
        const data = 'data:text/json;charset=utf-8,' + encodeURIComponent(exportData.toString())
        const downloadAnchorNode = document.createElement('a')
        downloadAnchorNode.setAttribute('href', data)
        downloadAnchorNode.setAttribute('download', `${fileName}.json`);
        document.body.appendChild(downloadAnchorNode)
        downloadAnchorNode.click()
        downloadAnchorNode.remove()
        setExportData(null)
        setFetchingInProgress(false)
    }

    return (
        <>
            <Panel>
                <b>Zalogowano jako:</b><br />{user.displayName}<br/>{user.email}
                <Btn variant={'danger'} block onClick={logout}>Wyloguj z systemu</Btn>
            </Panel>
            <Panel>
                <Btn variant={'secondary'} block onClick={clickExportPublicPayments}>Eksportuj publiczne płatności</Btn>
                <Btn variant={'secondary'} block onClick={clickExportPrivatePayments}>Eksportuj prywatne płatności</Btn>
                <Btn variant={'secondary'} block onClick={clickExportPublicDictionary}>Eksportuj publiczny słownik</Btn>
                <Btn variant={'secondary'} block onClick={clickExportPrivateDictionary}>Eksportuj prywatny słownik</Btn>
                {
                    fetchingInProgress
                    &&
                    <Btn variant={'primary'}
                         block
                         onClick={downloadExportData}
                         style={{marginTop: '20px'}}
                         disabled={exportData === null}>
                        {exportData === null ? 'Pobieranie...' : 'Pobierz plik z danymi'}
                    </Btn>
                }
            </Panel>
        </>
    )
}

export default ProfilePage
