import styled from 'styled-components'

const MainContainer = styled.div`
text-align: center;
`

const SmallDataContainer = styled.div`
display: flex;
`

const SmallDataItem = styled.div`
flex: 1;
font-size: 18px;
margin: 6px 3px;
padding-bottom: 3px;
background-color: #6b98e0;
`

const SmallDataTitle = styled.div`
font-size: 12px;
background-color: #466391;
color: #fff;
padding: 1px;
margin-bottom: 4px;
`

const BigDataContainer = styled.div`
font-size: 48px;
margin: 6px 3px;
padding: 3px;
background-color: #6b98e0;
`

const Header = styled.div`
font-weight: bold;
margin: 20px 3px 0 3px;
padding: 6px 3px;
background-color: #466391;
color: #fff;
`

export {
    MainContainer,
    SmallDataContainer,
    SmallDataItem,
    SmallDataTitle,
    BigDataContainer,
    Header
}
