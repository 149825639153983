import firebase from '../firebase'

export const dictionaryList = (filterSign = null, hidePublic = false) => {
    return firebase.firestore().collection('dictionary').get()
        .then(collection => {
            const items = []
            collection.docs.forEach(item => {
                const d = item.data()
                if((filterSign === null && !d.hasOwnProperty('sign')) || (!d.hasOwnProperty('sign') && !hidePublic) || filterSign === d.sign) {
                    items.push({
                        id: item.id,
                        name: item.data().name,
                        sign: d.hasOwnProperty('sign')
                    })
                }
            })
            items.sort((a, b) => {
                if(a.name < b.name) return -1
                if(a.name > b.name) return 1
                return 0
            })
            return items
        })
        .catch(err => alert(`ERROR ${err.code}`))
}

export const deleteFromDictionary = id => {
    return firebase.firestore().collection('dictionary').doc(id).delete()
        .catch(err => alert(`ERROR ${err.code}`))
}

export const addToDictionary = (name, privateSign) => {
    const obj = { name }
    if(privateSign) obj.sign = privateSign
    return firebase.firestore().collection('dictionary').add(obj)
        .then(ref => ({ ...obj, id: ref.id }))
        .catch(err => alert(`ERROR ${err.code}`))
}
