import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleUp, faAngleDoubleDown, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import daysInMonth from '../utils/daysInMonth'
import monthName from '../utils/monthName'

const Container = styled.div`
 margin: 5px 3px;
`

const ScreenContainer = styled.div`
  display: flex;
`

const ScreenItem = styled.div`
  flex: 1;
  font-size: 26px;
  margin: 3px;
  padding: 4px;
  background-color: #eeeeee;
  border: 1px #000 solid;
  border-radius: 4px;
`

const ControlContainer = styled.div`
  display: flex;
`

const ControlItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;  
`

const ControlButton = styled(Button)`
  flex: 1;
  margin: 4px;
  padding: 10px 0;
`

const ResetButton = styled(Button)`
  width: calc(100% - 6px);
  margin: 3px;
`

const ArrowButton = ({toUp, bigStep, enabled, value, onClick}) => {
    const click = () => onClick(value + (bigStep ? 10 : 1) * (toUp ? 1 : -1))
    const icon =
        bigStep ?
        toUp ? faAngleDoubleUp : faAngleDoubleDown
        :
        toUp ? faAngleUp : faAngleDown
    return (
        <ControlButton variant={'secondary'} onClick={click} disabled={!enabled}>
            <FontAwesomeIcon icon={icon}/>
        </ControlButton>
    )
}

const DatePicker = ({onChange}) => {
    const today = new Date()
    const [day, setDay] = useState(today.getDate())
    const [month, setMonth] = useState(today.getMonth() + 1)
    const [year, setYear] = useState(today.getFullYear())

    useEffect(() => {
        if(onChange) onChange({ day, month, year })
    }, [day, month, year])

    useEffect(() => {
        if(day > daysInMonth(month, year)) setDay(daysInMonth(month, year))
    }, [month, year])

    const goToToday = () => {
        setDay(today.getDate())
        setMonth(today.getMonth() + 1)
        setYear(today.getFullYear())
    }
    const dayBigChangeAvailable = toUp => (toUp ? day + 10 <= daysInMonth(month, year) : day - 10 > 0)
    const dayChangeAvailable = toUp => (toUp ? day < daysInMonth(month, year) : day > 1)
    const monthChangeAvailable = toUp => (toUp ? month < 12 : month > 1)
    const yearChangeAvailable = toUp => (toUp ? today.getFullYear() + 2 > year : today.getFullYear() - 2 < year)

    return (
        <Container>
            <ResetButton variant={'secondary'} block onClick={goToToday}>Ustaw na dzisiaj</ResetButton>
            <ScreenContainer>
                <ScreenItem>{day}</ScreenItem>
                <ScreenItem>{monthName(month)}</ScreenItem>
                <ScreenItem>{year}</ScreenItem>
            </ScreenContainer>
            <ControlContainer>
                <ControlItem>
                    <ArrowButton toUp value={day} onClick={setDay} enabled={dayChangeAvailable(true)}/>
                    <ArrowButton value={day} onClick={setDay} enabled={dayChangeAvailable(false)}/>
                </ControlItem>
                <ControlItem>
                    <ArrowButton toUp bigStep value={day} onClick={setDay} enabled={dayBigChangeAvailable(true)}/>
                    <ArrowButton bigStep value={day} onClick={setDay} enabled={dayBigChangeAvailable(false)}/>
                </ControlItem>
                <ControlItem>
                    <ArrowButton toUp value={month} onClick={setMonth} enabled={monthChangeAvailable(true)}/>
                    <ArrowButton value={month} onClick={setMonth} enabled={monthChangeAvailable(false)}/>
                </ControlItem>
                <ControlItem>
                    <ArrowButton toUp value={year} onClick={setYear} enabled={yearChangeAvailable(true)}/>
                    <ArrowButton value={year} onClick={setYear} enabled={yearChangeAvailable(false)}/>
                </ControlItem>
            </ControlContainer>
        </Container>
    )
}

export default DatePicker
