import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
text-align: center;
padding: 30px 5px;
`

export default () => (
    <Container>Ładowanie...</Container>
)
