import firebase from '../firebase'
import dateSortFunction from '../../utils/dateSortFunction'

export const paymentList = (sign, month, year) => {
    return firebase.firestore().collection('payments').get()
        .then(collection => {
            const total = { ad: 0, am: 0, dm: 0, da: 0 }
            const items = []
            const fullRange = !Number.isInteger(month) || !Number.isInteger(year)
            collection.docs.forEach(payment => {
                const d = payment.data()
                if(fullRange || (d.month === month && d.year === year)) {
                    let correctSource = true
                    if(sign === 'A' && (d.da > 0 || d.dm > 0)) correctSource = false
                    if(sign === 'D' && (d.ad > 0 || d.am > 0)) correctSource = false
                    if(correctSource) {
                        total.ad += d.ad
                        total.am += d.am
                        total.dm += d.dm
                        total.da += d.da
                        items.push({
                            ...d,
                            id: payment.id
                        })
                    }
                }
            })
            items.sort(dateSortFunction)
            return { total, items }
        })
        .catch(err => alert(`ERROR ${err.code}`))
}

export const deletePayment = id => {
    return firebase.firestore().collection('payments').doc(id).delete()
        .catch(err => alert(`ERROR ${err.code}`))
}

export const addPayment = (userSign, date, sharedPayment, amount, description) => {
    const obj = {
        ad: 0,
        am: 0,
        dm: 0,
        da: 0,
        day: date.day,
        month: date.month,
        year: date.year,
        desc: description
    }
    if(sharedPayment) {
        if(userSign === 'D') {
            obj.dm = amount
        }else{
            obj.am = amount
        }
    }else{
        if(userSign === 'D') {
            obj.da = amount
        }else{
            obj.ad = amount
        }
    }
    return firebase.firestore().collection('payments').add(obj)
        .then(ref => ({ id: ref.id, payment: obj }))
        .catch(err => alert(`ERROR ${err.code}`))
}
