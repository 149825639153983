import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import {
    SmallDataContainer,
    SmallDataItem,
    SmallDataTitle,
} from './StyledBlocks'
import formatNumber from '../utils/formatNumber'
import addLeadingZero from '../utils/addLeadingZero'
import monthName from '../utils/monthName'
import { deletePrivatePayment } from '../firebase/firestore/privatePaymentList'
import {paymentTypes} from './PrivateFilterForm'

const Container = styled.div`
margin: 8px 3px 3px 3px;
padding: 3px 3px 0 3px;
border: 1px #696969 solid;
position: relative;
`

const TopHolder = styled.div`
margin: 0 40px 2px 40px;
`

const DeleteButton = styled(Button)`
position: absolute;
left: 6px;
top: 4px;
font-size: 12px;
`

const getDate = ({day, month, year}) => (
    `${addLeadingZero(day)} ${monthName(month)} ${year}`
)

const getTypeName = type => {
    switch (type) {
        case paymentTypes.own: return 'Własna'
        case paymentTypes.common: return 'Wspólna'
        case paymentTypes.special: return 'Specjalna'
        default: return ''
    }
}

const PrivateHistoryItem = ({deleteButton, item, onDelete}) => {
    const [modalOpen, setModalOpen] = useState(null)

    const deleteItem = () => {
        deletePrivatePayment(item.id).then(() => {
            setModalOpen(false)
            if(onDelete) onDelete(item.id)
        })
    }

    return (
        <Container>
            <TopHolder><b>{getDate(item)}</b> - {item.desc}</TopHolder>
            <SmallDataContainer>
                <SmallDataItem><SmallDataTitle>Kwota</SmallDataTitle>{formatNumber(item.amount)}</SmallDataItem>
                <SmallDataItem><SmallDataTitle>Typ płatności</SmallDataTitle>{getTypeName(item.paymentType)}</SmallDataItem>
            </SmallDataContainer>
            {
                deleteButton && item.id
                &&
                <DeleteButton variant={'danger'} size={'sm'} onClick={() => setModalOpen(true)}>
                    <FontAwesomeIcon icon={faTrash}/>
                </DeleteButton>
            }
            <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Potwierdź operację</Modal.Title>
                </Modal.Header>
                <Modal.Body>Czy na pewno chcesz usunąć prywatną pozycję "{item.desc}" z dnia {getDate(item)}?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteItem}>Usuń</Button>
                    <Button variant="secondary" onClick={() => setModalOpen(false)}>Anuluj</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default PrivateHistoryItem
