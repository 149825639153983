import React from 'react'
import styled from 'styled-components'
import { Button, ButtonGroup } from 'react-bootstrap'

const Group = styled(ButtonGroup)`
width: 100%;
`

const OptionSwitch = ({size, items, value, onChange}) => {

    return (
        <Group size={size}>
            {
                items.map(item => (
                    <Button key={item.value}
                            variant={value === item.value ? 'success' : 'secondary'}
                            onClick={() => onChange && onChange(item.value)}>
                        {item.name}
                    </Button>
                ))
            }
        </Group>
    )
}

export default OptionSwitch
