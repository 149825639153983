import React, {useContext, useEffect, useState} from 'react'
import styled from 'styled-components'
import { Button, Form } from 'react-bootstrap'
import { addToDictionary } from '../firebase/firestore/dictionaryList'
import getUserSign from '../utils/getUserSign'
import {UserContext} from '../firebase/UserContext'
import OptionSwitch from './OptionSwitch'

const Container = styled(Form)`
  margin: 5px 3px 0 3px;
`

const AddButton = styled(Button)`
  margin-top: 5px;
`

const privateSwitch = [
    { name: 'Publiczny', value: false },
    { name: 'Prywatny', value: true }
]

const DictionaryNewItemForm = ({itemAdded}) => {
    const userSign = getUserSign(useContext(UserContext))
    const [name, setName] = useState('')
    const [isPrivate, setIsPrivate] = useState(false)
    const [verified, setVerified] = useState(false)

    useEffect(() => {
        setVerified(name.length > 2 && name.length < 25)
    }, [name])

    const addItem = () => {
        addToDictionary(name, isPrivate ? userSign : null)
            .then(newItem => {
                setName('')
                if(itemAdded) itemAdded(newItem)
            })
    }

    return (
        <Container>
            <OptionSwitch items={privateSwitch}
                          value={isPrivate}
                          onChange={setIsPrivate} />
            <Form.Control type={'text'}
                          placeholder={'Nazwa'}
                          style={{marginTop: '5px'}}
                          value={name}
                          onChange={e => setName(e.target.value)} />
            <AddButton variant={'success'} disabled={!verified} block onClick={addItem}>
                Dodaj
            </AddButton>
        </Container>
    )
}

export default DictionaryNewItemForm
